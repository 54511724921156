<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span>
        {{
          formData && formData.title
            ? $t("edit_file") + " - " + formData.title
            : $t("add_file")
        }}
      </span>
      <v-spacer />
      <v-icon @click="$emit('cancel')">mdi-close</v-icon>
    </v-toolbar>

    <v-form ref="form" class="pa-6">
      <v-row>
        <v-text-field
          :label="$t('title')"
          type="text"
          v-model="formData.title"
          :rules="formRules.required"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-textarea
          :label="$t('description')"
          type="text"
          v-model="formData.description"
          counter="1024"
          maxlength="1024"
          :rules="formRules.optional"
        ></v-textarea>
      </v-row>
      <v-row>
        <v-file-input
          :label="$t('file')"
          :value="fileInfo"
          @change="fileName_change"
          :rules="formRules.required"
        ></v-file-input>
      </v-row>
    </v-form>

    <!-- errors -->
    <div class="d-flex flex-row px-3" v-if="error">
      <span class="subtitle-1 mb-4 red--text">
        {{ $t("form_invalid_text") }}: {{ error }}
      </span>
    </div>

    <v-card-actions>
      <!-- <v-btn class="mr-2" color="default" @click="$emit('cancel')">
        {{ $t("close") }}
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="save">
        {{ $t("upload") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsFileEditor",
  props: {},
  data() {
    return {
      formData: {
        title: null,
        description: null,
        fileName: null,
        fileContent: null,
      },
      formRules: {
        required: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredIfCreate: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredArray: [
          (value) =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true,
        ],
        optional: [(value) => true],
      },
      fileInfo: null,
      valid: true,
      error: null,
      loading: false,
    };
  },
  methods: {
    async create() {
      this.clearForm();
    },
    async edit(fileId) {
      this.clearForm();
      if (typeof fileId == "string") {
        this.formData = await this.getFile(fileId);
      } else {
        this.formData = JSON.parse(JSON.stringify(fileId));
      }

      this.fileInfo = this.formData.fileName
        ? new File([], this.formData.fileName)
        : null;

      // console.log(this.formData)
    },
    async getFile(id) {
      try {
        const result = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/files/${id}`,
          method: "GET",
        });
        return result.data;
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async save() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close"),
          },
        });
        return;
      }

      const url = this.formData.fileId
        ? `${process.env.VUE_APP_BFF_ORIGIN}/files/${this.formData.fileId}`
        : `${process.env.VUE_APP_BFF_ORIGIN}/files`;

      const method = this.formData.fileId ? "PATCH" : "POST";

      const data = new FormData();
      data.append("title", this.formData.title);

      if (this.formData.description) {
        data.append("description", this.formData.description);
      } else {
        data.append("description", " ");
      }

      if (typeof this.formData.fileContent !== "undefined") {
        data.append("fileName", this.formData.fileName);
        data.append("fileContent", this.formData.fileContent);
      }

      try {
        this.loading = true;
        const result = await callAPI({ url, method, data });
        this.$emit("saved", result.data);
        this.clearForm();
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.$refs.form.resetValidation();
      this.valid = true;
      this.formData = {
        title: null,
        description: null,
        fileName: null,
        fileContent: null,
      };
      this.error = null;
      this.fileInfo = null;
    },
    fileName_change(v) {
      this.fileInfo = v;

      if (!this.fileInfo) {
        this.formData.fileName = null;
        this.formData.fileContent = null;
        return;
      }

      this.formData.fileName = this.fileInfo.name;
      this.formData.fileContent = this.fileInfo;
    },
  },
};
</script>
