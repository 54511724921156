<template>
  <v-container fluid class="pa-6">
    <!-- toolbar -->
    <v-row>
      <v-btn dark color="primary" @click="createFile">
        <v-icon small class="mr-2">mdi mdi-plus</v-icon>
        {{ $t("add") }}
      </v-btn>
    </v-row>
    <!-- filter panel -->
    <v-row>
      <GsDataFilter
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </v-row>
    <!-- table -->
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <v-card>
          <GsDataTable
            ref="table"
            api=""
            endpoint="/files"
            :appSetting="appSettingKey"
            :headers="headers"
            :sort-by="['title']"
            :sort-desc="[false]"
            :beforeCallApi="beforeCallApi"
            :afterCallApi="afterCallApi"
          >
            <template v-slot:item.actions="{ item }">
              <GsActionsMenu
                :actions="actions"
                :onActivate="(actionId) => action_activate(actionId, item)"
              />
            </template>

            <template v-slot:item.type="{ item }">
              <v-img
                :src="
                  require('@/assets/file-type-icons/' +
                    getFileIcon(item.fileName) +
                    '.svg')
                "
                contain
                class="mx-auto fileManagerIcons"
                width="32px"
                height="32px"
              ></v-img>
            </template>

            <template v-slot:item.title="{ item }">
              <span class="col-name">{{ item.title }}</span>
            </template>

            <template v-slot:item.description="{ item }">
              <span class="col-name">{{ item.description }}</span>
            </template>

            <template v-slot:item.fileName="{ item }">
              <span class="col-name">{{ item.fileName }}</span>
            </template>

            <template v-slot:item.fileSize="{ item }">
              <span class="col-name">{{ getFileSize(item.fileSize) }}</span>
            </template>

            <template v-slot:item.createdAt="{ item }">
              <span>
                {{ item.createdAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$createdBy | empty }})
              </span>
            </template>
          </GsDataTable>
        </v-card>
      </v-container>
    </v-row>
    <!-- add/edit file -->
    <v-dialog v-model="dialogEditorVisible" persistent max-width="600px">
      <GsFileEditor ref="fileEditor" @saved="modalSave" @cancel="modalCancel" />
    </v-dialog>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";
const { formatBytes } = require("@/utils/formatBytes.js");
const { fileTypeIcon } = require("@/utils/fileTypeIcon.js");
const { fileSave } = require("@/utils/fileSave.js");
import GsFileEditor from "@/components/GsFileEditor";

const PAGE_LIMIT = 30;

export default {
  name: "Files",
  components: {
    GsFileEditor,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
  },
  data() {
    return {
      appSettingKey: "files",
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$t("title"),
          value: "title",
          width: "20%",
          sortable: true,
        },
        {
          text: this.$t("description"),
          value: "description",
          width: "30%",
          sortable: true,
        },
        {
          text: this.$t("name"),
          value: "fileName",
          width: "20%",
          sortable: true,
        },
        {
          text: this.$t("size"),
          value: "fileSize",
          width: "10%",
          sortable: true,
        },
        {
          text: this.$t("type"),
          align: "center",
          value: "type",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: 150,
          sortable: true,
        },
      ],
      dialogEditorVisible: false,
      filters: [
        {
          field: "createdBy",
          component: "GsSelectFilter",
          params: {
            options: [],
          },
        },
      ],
      initialized: false,
      actions: ["download", "edit", "delete"],
    };
  },
  async mounted() {
    this.initialized = false;
    await this.fillCreatedByOptions();
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      // params.url += `&ownedBy=${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = item?.createdBy?.name || "";
        item.$calculated.$updatedBy = item?.updatedBy?.name || "";
      }
      return params;
    },
    async createFile() {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.fileEditor) {
          return;
        }
        await this.$refs.fileEditor.create();
      });
    },
    async edit_click(file) {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.fileEditor) {
          return;
        }
        await this.$refs.fileEditor.edit(file);
      });
    },
    modalCancel() {
      this.dialogEditorVisible = false;
    },
    async modalSave() {
      this.dialogEditorVisible = false;
      this.$refs.table.refreshTable();
    },
    async delete_click(file) {
      const result = confirm(`${this.$t("delete_modal")}\n\n${file.title}`);
      if (!result) {
        return;
      }

      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/files/${file.fileId}`;
        const result = await callAPI({
          url,
          method: "DELETE",
        });
        if (result.status === 204) {
          this.$refs.table.refreshTable();
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    getFileSize(fileSize) {
      return formatBytes(fileSize);
    },
    getFileIcon(fileName) {
      return fileTypeIcon(fileName);
    },
    async download_click(file) {
      try {
        const { data, headers } = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/files/${file.fileId}/fileContent`,
          method: "GET",
          resType: "blob",
        });
        const fileName = headers["content-disposition"]
          ? headers["content-disposition"].match(/filename="(.*)"/)[1]
          : file.fileName;
        fileSave(data, fileName);
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "download":
          this.download_click(item);
          break;
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    async onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async fillCreatedByOptions() {
      const data = await this.getUsers();
      if (!data) return;
      this.filters.find((p) => p.field === "createdBy").params.options =
        data.map((p) => ({ value: p._uri, text: p.name }));
    },
  },
};
</script>

<style lang="scss">
.comp-logo {
  width: 26px;
  height: 26px;
  display: inline-block;
}
.comp-name {
  position: relative;
  top: -8px;
}
.file-item {
  width: 23%;

  .fileName {
    height: 4.4rem;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }

  .fileDescription {
    height: 5.6rem;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }

  .fileManagerIcons {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 1400px) {
  .file-item {
    width: 23%;
  }
}
@media (max-width: 1000px) {
  .file-item {
    width: 30%;

    .fileManagerIcons {
      width: 60px;
      height: 60px;
    }
  }
}
@media (max-width: 800px) {
  .file-item {
    width: 45%;

    .fileManagerIcons {
      width: 70px;
      height: 70px;
    }
  }
}
@media (max-width: 600px) {
  .file-item {
    width: 100%;

    .fileManagerIcons {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
